import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { IonicStorageModule } from '@ionic/storage-angular';
import { ComponentsModule } from './core/components/components.module';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
registerLocaleData(ptBr)
@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    CoreModule,
    AppRoutingModule,
    ComponentsModule,
    IonicStorageModule.forRoot({
      name: '__ticsign'
    })
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

