import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvatarComponent } from './avatar/avatar.component';
import { MenuAvatarComponent } from './menu-avatar/menu-avatar.component';
import { MenuComponent } from './menu/menu.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { IonicModule } from '@ionic/angular';
import { SignaturePadComponent } from './signature-pad/signature-pad.component'
import { NgxFileDropModule } from 'ngx-file-drop';




@NgModule({
  declarations: [AvatarComponent, MenuAvatarComponent, MenuComponent, PageHeaderComponent, SignaturePadComponent],
  imports: [
    CommonModule,
    IonicModule,
    NgxFileDropModule
  ],
  exports: [
    AvatarComponent, MenuAvatarComponent, MenuComponent, PageHeaderComponent, SignaturePadComponent
  ]
})
export class ComponentsModule { }
