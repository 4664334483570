import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

export interface Token {
  user: DadosUser;
}

export interface DadosUser {
  id: number
  birthdate: string
  created_at: string;
  email: string;
  first_name: string;
  full_name: string;
  last_name: string;
  number_cpf: string
  number_phone: string
  token: string;
  token_exp: number;
  updated_at: string;
  indadmin: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {


  
  constructor(
    private storage: Storage,
  ) { }

  async createDatabase() {
    try {
        await this.storage.create();        
    } catch (error) {
        console.error("Error creating database:", error);
        throw new Error("Database creation failed");
    }
  }

  async isDatabaseCreated() {
    try {
        // Tente acessar uma chave que você sabe que deve existir
        // Caso a chave não exista, considere o banco de dados não criado
        await this.storage.get('testKey');
        return true;
    } catch (error) {
        // Se houver um erro, provavelmente o banco de dados não foi criado
        return false;
    }
  }

  async getTokenConexao(): Promise<Token> {
    const dbCreated = await this.isDatabaseCreated();
    if (!dbCreated) {
      try {
          await this.createDatabase();
      } catch (error) {
          console.error("Failed to create database:", error);
          return; // Opcional: Pare a inicialização do aplicativo se o banco de dados não puder ser criado
      }
    }
    return await this.storage.get('token')
  }

  async setToken(Token: Token): Promise<void> {
    return await this.storage.set('token', Token);
  }

  async destroyToken(): Promise<void> {
    return new Promise(async (resolve) => {
      await this.storage.remove('token');
      resolve();
    })
  }
}
