import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Token, TokenService } from '../../services/token.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'ticsign-menu-avatar',
  templateUrl: './menu-avatar.component.html',
  styleUrls: ['./menu-avatar.component.scss'],
})
export class MenuAvatarComponent implements OnInit {

  imgAvatar: string = undefined;
  fullName: string = undefined;
  email: string = undefined;

  constructor(
    private utilService: UtilService,
    private tokenService: TokenService,
    private menuController: MenuController,
    private router: Router,
  ) { }

  destroyLogin() {
    this.menuController.enable(false, 'menuAvatar');
    this.menuController.enable(false, 'menuTicsign');
    this.menuController.close('menuAvatar');
    this.tokenService.destroyToken();
    this.router.navigate(['/login']);
  }

  goRoute(route) {
    this.router.navigate([route]);
    this.menuController.close('menuAvatar');
  }

  ngOnInit() {
    const SetAvatar = setInterval(
      () => {
        if (this.fullName == undefined) {
          this.geraAvatar()
        } else {
          clearInterval(SetAvatar);
        }
      }, 1000
    )
  }

  private async geraAvatar() {
    await this.setSigla()
    if (this.fullName != undefined) {
      this.imgAvatar = this.utilService.geraAvatar(this.fullName);
    }
  }

  private async setSigla(): Promise<void> {
    await this.tokenService.getTokenConexao().then(
      (retorno: Token) => {
        if (retorno != null) {
          this.fullName = retorno.user.full_name;
          this.email = retorno.user.email;
        }
      }
    )
  }

}
