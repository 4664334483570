import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIURL } from '../../../environments/environment'
import { AuthUser } from './auth.types';
import { Token, TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public loginSessao: Token = null as Token;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
  ) {   
      new Promise((resolve) => { 

        
        this.tokenService.getTokenConexao().then(
          (tokenLogin) => {
            if (tokenLogin != null) {          
              this.loginSessao = tokenLogin;
              resolve(true);
            }
          }
        );
      });
  }

  login(user: AuthUser) {
    const postData = {
      email: user.email,
      password: user.password
    };    
    return this.http.request(
      'POST',
      APIURL + '/login',
      {
        headers: this.getHttpOptions({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
          'Access-Control-Allow-Headers': 'access-control-allow-origin, access-control-allow-headers',
          'Access-Control-Allow-Credentials': 'true',
        }),
        params: postData
      });
  }

   sendData(method, route: string, params: any, paramsFile: File[] = undefined, extraHeaders: { [name: string]: string; } = {}, token:any = undefined): Observable<any> {
    let parametroEnvio = new HttpParams().appendAll(params);  
    
    if (this.loginSessao != null) {
      extraHeaders['Authorization'] = 'Bearer ' + this.loginSessao.user.token;
    }else if (token != null) {      
      extraHeaders['Authorization'] = 'Bearer ' + token;
    }

    const req = new HttpRequest(
      method,
      APIURL + '/' + route,
      this.buildFormDataFile(paramsFile),
      {
        headers: this.getHttpOptions(extraHeaders),
        params: parametroEnvio,
      }
    )          
    return this.http.request(req);
  }

  sendDataBody(
    method: string,
    route: string,
    params: any,
    paramsFile: File[] = undefined,
    extraHeaders: { [name: string]: string; } = {},
    token: any = undefined,
    body_params: any = undefined
  ): Observable<any> {
    
    // Configurar headers com token se necessário
    if (this.loginSessao != null) {
      extraHeaders['Authorization'] = 'Bearer ' + this.loginSessao.user.token;
    } else if (token != null) {
      extraHeaders['Authorization'] = 'Bearer ' + token;
    }
  
    // Se houver arquivos, envie com FormData
    let body: any;
    if (paramsFile !== undefined) {
      body = this.buildFormDataFile(paramsFile); // Construa um FormData se houver arquivos
      if (body_params) {
        // Adicione outros dados ao FormData, se houver
        for (const key in body_params) {
          if (body_params.hasOwnProperty(key)) {
            body.append(key, body_params[key]);
          }
        }
      }
    } else {
      // Se não houver arquivos, envie body_params como JSON
      body = body_params;
    }
  
    // Crie a requisição HTTP
    const req = new HttpRequest(
      method,
      APIURL + '/' + route,
      body,
      {
        headers: this.getHttpOptions(extraHeaders),
      }
    );
    
    return this.http.request(req);
  } 

  getFile(method, route: string, params: any, extraHeaders: { [name: string]: string; } = {}, token:any = undefined): Observable<any> {    
    let parametroEnvio = new HttpParams().appendAll(params);
    if (this.loginSessao != null) {
      extraHeaders['Authorization'] = 'Bearer ' + this.loginSessao.user.token;
    }else if (token != null) {      
      extraHeaders['Authorization'] = 'Bearer ' + token;
    }

    const req = new HttpRequest(
      method,
      APIURL + '/' + route,
      this.buildFormDataFile(undefined),
      {
        headers: this.getHttpOptions(extraHeaders),
        params: parametroEnvio,
        responseType: 'blob'
      }
    )
    return this.http.request(req);
  }

  private getHttpOptions(params?: { [name: string]: string }): HttpHeaders {
    return new HttpHeaders({
      ...params
    });
  }

  private buildFormDataFile(paramsFile): FormData {
    const formData = new FormData();
    if (paramsFile !== undefined) {
      for (const file of paramsFile) {
        formData.append('file_anexo[]', file, file.name);
      }
    }
    return formData;
  }
}
