import { Injectable } from '@angular/core';
import { OverlayService } from './overlay.service';
import { Token, TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private overlayService: OverlayService,
    private tokenService: TokenService,
  ) { }

  public geraAvatar(name: string, size?: number) {
    name = name || ''
    size = size || 60
    var colours = [
      '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50',
      '#f1c40f', '#e67e22', '#e74c3c', '#ecf0f1', '#95a5a6', '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d',
      '#ec87bf', '#d870ad', '#f69785', '#9ba37e', '#b49255', 'a94136'
    ]

    var nameSplit = String(name).toUpperCase().split(' '), initials, charIndex, colourIndex, canvas, context, dataURI
    if (nameSplit.length === 1) {
      initials = (nameSplit[0] ? nameSplit[0].charAt(0) : '?')
    } else {
      initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0)
    }
    if (window.devicePixelRatio) {
      size = (size * window.devicePixelRatio)
    }

    charIndex = (initials === '?' ? 72 : initials.charCodeAt(0)) - 64
    colourIndex = charIndex % 20
    canvas = document.createElement('canvas')
    canvas.width = size
    canvas.height = size
    context = canvas.getContext('2d')

    context.fillStyle = colours[colourIndex - 1]
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.font = Math.round(canvas.width / 2) + 'px Arial'
    context.textAlign = 'center'
    context.fillStyle = '#FFF'
    context.fillText(initials, size / 2, size / 1.5)


    dataURI = canvas.toDataURL()
    canvas = null
    return dataURI
  }

  public handlesError(err) {
    console.log('handlesError');
    console.log(err);
    var errors = err.error.errors || err.error || {}
    var list = []
    for (var key in errors) {
      var label = key;
      if (!(errors && errors[key])) continue
      var obj = errors[key]
      if (typeof obj === 'string') {
        list.push(obj)
        continue
      }
      for (var prop in obj) {
        if (!(obj && obj[prop])) continue
        list.push("<li>" + label + ': ' + obj[prop] + "</li>")
      }
    }
    if (list.length === 0) {
      list.push('Tente novamente')
    }
    let retorno = err.error.message || 'Atenção!';
    return retorno + "<ul>" + list.toString() + "</ul>"
  }

  public async alertaCamposObrigatorios(msg: string[] = []): Promise<void> {
    let html = '<p>Preenchimento obrigatório dos campos:</p>';
    msg.forEach((ms) => {
      html += '<li> '.concat(ms).concat('</li>');
    });

    await this.overlayService.alert({
      header: 'Registro inválido.',
      message: html,
      buttons: ['OK']
    });
  }

  public async getTokenConexao(): Promise<Token> {
    return new Promise((resolve) => {
      this.tokenService.getTokenConexao().then(
        (retorno: Token) => resolve(retorno)
      )
    })
  }

  public async blobToBase64(blob): Promise<string | ArrayBuffer> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        let base64String = reader.result;
        resolve(base64String);
      }
    });
  }

  /**
    * Convert BASE64 to BLOB
    */
  public convertBase64ToBlob(base64Image: string) {
    // Split into two parts
    const parts = base64Image.split(';base64,');

    // Hold the content type
    const imageType = parts[0].split(':')[1];

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }

}
