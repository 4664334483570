import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'cadastro',
    loadChildren: () => import('./pages/cadastro/cadastro.module').then((m) => m.CadastroModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'signatory',
    loadChildren: () => import('./pages/cadastro/signatory/signatory.module').then((m) => m.SignatoryPageModule),
    canActivate: [AuthGuard],
  },  
  {
    path: 'sign',
    loadChildren: () => import('./pages/sign/sign.module').then((m) => m.SignModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sign/signing-file',
    loadChildren: () => import('./pages/sign/signing-file/signing-file.module').then(m => m.SigningFilePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'document-account',
    loadChildren: () => import('./pages/report/document-account/document-account.module').then( m => m.DocumentAccountPageModule)
  },  
  {
    path: 'filtra-registros',
    loadChildren: () => import('./pages/report/filtra-registros/filtra-registros.module').then( m => m.FiltraRegistrosPageModule)
  },
  {
    path: 'config',
    loadChildren: () => import('./pages/config/main/main.module').then( m => m.MainPageModule)
  },
  {
    path: 'package',
    loadChildren: () => import('./pages/cadastro/package/package.module').then( m => m.PackagePageModule)
  },
  {
    path: 'package-account',
    loadChildren: () => import('./pages/cadastro/package-account/package-account.module').then( m => m.PackageAccountPageModule)
  },
  {
    path: 'document-listing',
    loadChildren: () => import('./pages/report/document-listing/document-listing.module').then( m => m.DocumentListingPageModule)
  },
  {
    path: 'document-package-account',
    loadChildren: () => import('./pages/report/document-package-account/document-package-account.module').then( m => m.DocumentPackageAccountPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/cadastro/user/user.module').then( m => m.UserPageModule)
  },   {
    path: 'transactions',
    loadChildren: () => import('./pages/report/transactions/transactions.module').then( m => m.TransactionsPageModule)
  },
  {
    path: 'version-modal',
    loadChildren: () => import('./pages/modal/version-modal/version-modal.module').then( m => m.VersionModalPageModule)
  },
  
 


 


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
