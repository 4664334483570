import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { OverlayService } from '../overlay.service';
import { UtilService } from '../util.service';
import { Company } from './company.service';
import { User } from './user.service';

export interface Accounts {
  users: User[],
  user_companies: Company[]
}

export interface PackageAccount {  
  id: number;
  user_id: number;
  users_company_id: number;
  package_id: number;
  date_purchase: string;
  created_at: string;
  updated_at: string;
  cancellation_date: string;
}

@Injectable({
  providedIn: 'root'
})
export class PackageAccountService {

  constructor(
    private overlayService: OverlayService,
    private apiService: ApiService,
    private utilService: UtilService,
  ) { }

  
  async deletePackageAccount(package_account_id,token): Promise<any> {
    const loading = await this.overlayService.loading();
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'DELETE',
        "package_account/destroy",
        {
          package_account_id: package_account_id,          
        },[],{},token
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          window.location.reload();   
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    });
  }  


  async cancelPackageAccount(package_account_id,token): Promise<any> {
    const loading = await this.overlayService.loading();
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'GET',
        "package_account/cancel_package_account",
        {
          package_account_id: package_account_id,          
        },[],{},token
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          if (retorno.body != null) {            
            resolve(retorno.body);
          }
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    });
  }  



  async getDocumentNumberPackageAccount(user_id,company_id,token): Promise<any> {
    const loading = await this.overlayService.loading();
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'GET',
        "package_account/get_document_number_package_account",
        {
          user_id: user_id,
          company_id: company_id
        },[],{},token
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          if (retorno.body != null) {            
            resolve(retorno.body);
          }
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    });
  }  

  async deleteUser(user_id, token, delete_user_id): Promise<Accounts> {
    const loading = await this.overlayService.loading();
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'DELETE',
        "users/delete_user_all_references",
        {
          delete_user_id: delete_user_id
        },[],{},token
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          window.location.reload();   
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    });

  }


  async search(user_id, token, searchPF,searchPJ): Promise<Accounts> {
    const loading = await this.overlayService.loading();
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'GET',
        "package_account/search",
        {
          searchPF: searchPF ,
          searchPJ:   searchPJ       
        },[],{},token
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          if (retorno.body != null) {            
            resolve(retorno.body);
          }
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    });

  }

  async savePackageAccount(user_id, token, package_account ): Promise<Accounts> {
    const loading = await this.overlayService.loading();
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'POST',
        "package_account",
        {
          package_account: JSON.stringify(package_account) ,
          user_id:   user_id       
        },[],{},token
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          if (retorno.body != null) {            
            resolve(retorno.body);
          }
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    });
  }


  async getAllAccounts(user_id, token): Promise<Accounts> {
    const loading = await this.overlayService.loading();
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'GET',
        "package_account/list_accounts",
        {},[],{},token
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          if (retorno.body != null) {            
            resolve(retorno.body);
          }
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    });
  }

  async getManageData(user_id, token, account_user_id, account_company_id): Promise<Accounts> {
    const loading = await this.overlayService.loading();
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'GET',
        "package_account/get_manage_data",
        {
          user_id: account_user_id,
          company_id: account_company_id
        },[],{},token
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          if (retorno.body != null) {            
            resolve(retorno.body);
          }
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    });
  }
}
