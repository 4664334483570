import { Component, Input, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Token, TokenService } from '../../services/token.service';
import { UtilService } from '../../services/util.service';
import { PackageAccountService } from '../../services/cadastro/package-account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ticsign-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() titulo: string = undefined;
  
  @Input() document_number: any = undefined;

  imgAvatar: string = undefined;
  nome: string = undefined;
  tokenLogin: Token = {} as Token;  
  page_name : string = undefined;

  constructor(
    private menuController: MenuController,
    private utilService: UtilService,
    private tokenService: TokenService,
    private packageAccountService: PackageAccountService,
    private router: Router
  ) { }


  

 async  ngOnInit() {    
    await this.utilService.getTokenConexao().then((retorno) => { this.tokenLogin = retorno; } ); 
    this.geraAvatar();
  }


  openMenuPrincipal(): void {
    this.menuController.enable(true, 'menuTicsign');
    this.menuController.isOpen('menuTicsign').then(
      (retorno: boolean) => {
        if (retorno) {
          this.menuController.close('menuTicsign');
        } else {
          this.menuController.open('menuTicsign');
        }
      }
    )
  }

  openMenuAvatar() {
    this.menuController.enable(true, 'menuAvatar');
    this.menuController.isOpen('menuAvatar').then(
      (retorno: boolean) => {
        if (retorno) {
          this.menuController.close('menuAvatar');
        } else {
          this.menuController.open('menuAvatar');
        }
      }
    )
  }

  getShowCounter(){
    var result = false
    if ((this.document_number != undefined) || (this.document_number != 9999)){
      result = true;
    }
  }

  private async geraAvatar() {
    await this.setSigla()
    this.imgAvatar = this.utilService.geraAvatar(this.nome);
  }

  private async setSigla(): Promise<void> {
    await this.tokenService.getTokenConexao().then(
      (retorno: Token) => {
        this.nome = retorno.user.full_name;
      }
    )
  }

}
