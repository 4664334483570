import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Token, TokenService } from '../../services/token.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'ticsign-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  @Input() sigla: string = undefined;

  imgAvatar: string = undefined;

  constructor(
    private utilService: UtilService,
    private tokenService: TokenService,
  ) { }

  ngOnInit() {
    this.geraAvatar()
  }


  private async geraAvatar() {
    if (this.sigla == undefined) {
      await this.setSigla()
    }
    this.imgAvatar = this.utilService.geraAvatar(this.sigla);
  }

  private async setSigla(): Promise<void> {
    await this.tokenService.getTokenConexao().then(
      (retorno: Token) => {
        this.sigla = retorno.user.full_name;
      }
    )
  }

}
