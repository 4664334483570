import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AMBIENTE_DE_TESTES } from 'src/environments/environment';
import { ApiService } from '../../services/api.service';
import { Token } from '../../services/token.service';
import { UtilService } from '../../services/util.service';

import { CompanyEmployee } from 'src/app/core/services/cadastro/company-employees.service';

@Component({
  selector: 'ticsign-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  ambienteTestes: boolean = false;
  subMenuRelatorio: boolean = false;
  subMenuRelatorio2: boolean = false;
  empresas: CompanyEmployee[] = [];
  tokenLogin_id: number;
  tokenLogin: Token;
  menus: any = {};
  href: string;
  indadmin: boolean = false;

  constructor(
    private menuController: MenuController,
    private router: Router,
      
    private apiService: ApiService,
    private utilService: UtilService,    
  ) { 
    
    
  }

  async ngOnInit() {
    this.ambienteTestes = AMBIENTE_DE_TESTES;
    this.subMenuRelatorio = false;
    this.menus[0] = {show: true, sub1: { show: false}, sub2: { show: false} };
    
    this.menus[1] = {show: false};    
    this.menus[2] = {show: false};    
    
    await this.utilService.getTokenConexao().then((retorno) => {
      this.tokenLogin = retorno;
      if (this.tokenLogin != undefined){
        this.indadmin = this.tokenLogin.user.indadmin;
      };
    });    
    await this.companyMenu();
  }


  async companyMenu(): Promise<void> {        
    return new Promise((resolve, reject) => {
      if (this.tokenLogin != undefined){
        this.getCompanies(this.tokenLogin.user.id, this.tokenLogin.user.token).then(()=>{        
          resolve();         
        });
      };      
    });
  }

  async companyMenu2(retorno): Promise<any> {       
    return new Promise((resolve, reject) => {
      for (var i=0; i< retorno.body.length;i++){                
        this.menus[retorno.body[i].id] = {show: false, sub1: { show: false}, sub2: { show: false} };        
      };       
      resolve(true);               
    });
  }

  



  showMenuIndex(index){    
    if (this.menus[index].show == false){
      this.menus[index].show = true;
    }else{
      this.menus[index].show = false;
    };
  }

  showSubMenuIndex(index,sub){        
    if (sub == 'sub1'){
      if (this.menus[index].sub1.show == false){
        this.menus[index].sub1.show = true;
      }else{
        this.menus[index].sub1.show = false;
      };
    }else{
      if (this.menus[index].sub2.show == false){
        this.menus[index].sub2.show = true;
      }else{
        this.menus[index].sub2.show = false;
      };
    }
    
  }

  private async getCompanies(user_id: number, token: any): Promise<void> {    
    return new Promise((resolve, reject) => {
      
      this.apiService.sendData(
        'GET',
        "users/" +user_id + "/user_company_employees/list",
        {          
        },[],{},token
      ).subscribe(
        (retorno) => {          
          if (retorno.body != null) {             
            this.companyMenu2(retorno).then((retorno2)=>{
              if (retorno2 == true){

                this.empresas = retorno.body;
                resolve();
              };
            });
          }
        },
        (error) => {                    
          reject();
        }
      )
    });
  }

  goRoute(route) {    
    //this.router.navigate([route]);
    //this.menuController.close('menuTicsign');
  
    this.href = window.location.origin + '/' + route;
    window.open(this.href,"_self");
  }

  showSubMenuRelatorio(): void {    
    this.subMenuRelatorio = !this.subMenuRelatorio;
  }
  showSubMenuRelatorio2(){
    this.subMenuRelatorio2 = !this.subMenuRelatorio2;
  }

  goRoutePlus(route,params) {  
    var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&'); 
    
    if (queryString != undefined){
      this.href = window.location.origin + '/' + route + '?' + queryString;
    }else{
      this.href = window.location.origin + '/' + route; 
    }
    
    window.open(this.href,"_self");
     
    
  }  
}
