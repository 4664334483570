// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

//export const APIURL = 'http://10.0.77.244:8043/api/v1';
export const APIURL = 'https://api.ticsign.com.br/api/v1';
export const CONFIRM = '#/login/confirm-email';
export const PASSWORDRESET = '#/login/password-reset';
export const SIGNFILE = '#/sign/signing-file';
export const SIGNUP = '#/login/signup';
export const AMBIENTE_DE_TESTES = false;
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
