import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OverlayService } from '../services/overlay.service';
import { Token, TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuthState(state.url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }

  constructor(
    private tokenService: TokenService,
    private router: Router,
    private overlayService: OverlayService
  ) { }

  private async checkAuthState(redirect: String): Promise<boolean> {
    return this.tokenService.getTokenConexao().then(
      (token: Token) => {
        if (token == null) {
          this.router.navigate(['/login'], {
            queryParams: { redirect }
          });
          return false;
        } else {
          // Token expirado
          var data_expiracao = new Date(token.user.token_exp);
          var data_atual = new Date();
          if (data_expiracao < data_atual) {
            this.tokenService.destroyToken();
            this.router.navigate(['/login'], {
              queryParams: { redirect }
            });
            this.overlayService.toast({
              message: "Token expirado, favor realizar um novo login",
              buttons: ['OK'],
              color: "danger"
            })
            return false
          } else {
            return true
          }
        }
      }
    )
  }

}
