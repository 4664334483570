import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { OverlayService } from '../overlay.service';
import { UtilService } from '../util.service';

export interface HandwrittenSignatureFile {
  id: number;
  created_at: string;
}

@Injectable({
  providedIn: 'root'
})
export class HandwrittenSignaturesService {

  constructor(
    private overlayService: OverlayService,
    private apiService: ApiService,
    private utilService: UtilService,
  ) { }

  async getHandwrittenSignatures(email: string): Promise<HandwrittenSignatureFile[]> {
    const loading = await this.overlayService.loading({ message: "Carregando..." });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'GET',
        'handwritten_signature',
        {
          email: email
        }
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          if (retorno.body != null) {
            resolve(retorno.body.handwritten_signatures);
          }
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    })
  }

  async deleteSignProcess(handwrittenSignature: HandwrittenSignatureFile): Promise<void> {
    const loading = await this.overlayService.loading({ message: "Apagando..." });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'DELETE',
        'handwritten_signature/' + handwrittenSignature.id,
        {},
      ).subscribe(
        () => {
          loading.dismiss();
          resolve();
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    })
  }

  async getSignatureFile(file_id: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.apiService.getFile(
        'GET',
        'handwritten_signature/' + file_id,
        {}
      ).subscribe(
        (retorno) => {
          if (retorno.body != null) {
            resolve(retorno.body);
          }
        },
        (error) => {
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    })
  }

  async saveHandwrittenSignature(email: string, file: File): Promise<HandwrittenSignatureFile> {
    const loading = await this.overlayService.loading({ message: "Assinando..." });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        'POST',
        'handwritten_signature',
        {
          email: email,
        },
        [file]
      ).subscribe(
        (retorno) => {
          loading.dismiss();
          if (retorno.body != null) {
            resolve(retorno.body.handwritten_signature);
          }
        },
        (error) => {
          loading.dismiss();
          this.overlayService.toast({
            message: this.utilService.handlesError(error),
            buttons: ['OK'],
            color: "danger"
          })
          reject();
        }
      )
    })
  }
}
